import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";
import { blogList } from "../../data/blogs";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/tips-to-find-best-mortgage-lender");

  return (
    <LayoutWrapper
      title="5 Tips for Finding the Best Mortgage Lender"
      desc="It can be hard to know which mortgage lender fits your financial situation and goals. Read on as we discuss 5 tips to finding the best mortgage lender for you!"
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              5 Tips for Finding the Best Mortgage Lender
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  March 17, 2023
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_The Different Types of Home Loans_ Which One Is Right for You_.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The market is flooded with mortgage lenders, but when buying a home, who should you
                choose? Too often, borrowers rush through the lender shopping experience and settle
                for less. We get it—with so many lenders, it's hard to know which fits your
                financial situation and long-term goals. Below, we'll discuss some key factors to
                consider when selecting a mortgage lender to ensure you make an informed decision
                that aligns with your goals and needs.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Start with Lender Options & Rates
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Mortgage lenders offer different interest rates depending on your financial
                situation and repayment ability. While a fraction of a percentage point may seem
                negligible, high costs (or savings!) will add up over time.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For example, a one percent interest-rate difference on a $250,000 loan with a
                30-year fixed-rate mortgage could mean paying an extra $58,813 in interest payments
                throughout the loan. That's a lot of money! But unfortunately, nearly 50 percent of
                home buyers do not shop around when selecting mortgage lenders.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Shop Big, Small, & Online
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As we said, the market is crowded, so you have more choices. Use overcrowding to
                your advantage, and don't just shop online. Thousands of large and small banks,
                credit unions, and online lenders are out there. Just be patient and do your
                research. You have a lot to gain—and lose.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Here are three of the most common lenders:
              </p>

              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ul className="list-disc px-[20px] text-xs">
                  <li>
                    You can use them to purchase either a primary residence, a secondary home, or an
                    investment property
                  </li>
                  <li>Borrowing costs tend to be lower overall compared to other mortgages</li>
                  <li>Sellers can contribute to closing costs</li>
                </ul>
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Some of the downsides include:
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ul className="list-disc px-[20px] text-xs">
                  <li>
                    <strong>Local Banks:</strong> Believe it or not, many small community banks
                    offer the same loan options as national brands. The difference is local banks
                    offer a much more personal experience. Plus, they have more flexible lending
                    criteria. The downside? They generally have fewer loan options or robust online
                    resources.{" "}
                  </li>
                  <li>
                    <strong>Big Banks:</strong> National banks have more options, credibility, and
                    industry-standard rates. However, they may lack the personal touch you’re
                    looking for, especially if you’re a first-time buyer.{" "}
                  </li>
                  <li>
                    <strong>Online Lenders:</strong> Online lenders offer convenience and lower
                    rates because they do not have to shoulder the overhead of brick-and-mortar
                    institutions. The downside, however, is that they lack in-person support
                    services.{" "}
                  </li>
                </ul>
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Ask for Recommendations
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It may be tempting to begin your search online, but we recommend requesting
                referrals from someone you trust. Consider asking a friend, family member, or
                colleague about their experience with a lender. Look for a collaborative, attentive,
                communicative, and patient lender. If someone you know had a positive experience
                with a particular lender, there's a good chance you will too.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Never Stop at the Headline
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                In the media world, headlines generally give readers a sense of what the entire
                article is about. But headlines only give you a portion of the picture. The same
                goes for mortgage lending headlines. Many lenders advertise attractive interest
                rates; unfortunately, you may not be eligible. Interest rates and loan terms vary,
                depending on your credit score and finances. So do your due diligence. Never stop at
                the headline.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Shop Around: Negotiate Your Interest Rate
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Many borrowers are surprised to learn that they have the power to negotiate interest
                rates. To get the best rate possible, start shopping. Create a list comparing rates
                and include online quotes and closing fees from other lenders. Then, when you meet
                with your prospective lender, pull out your list of quotes and use them as leverage
                to negotiate a better rate.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You may not always get what you want—then again, you may!
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Ready to Look at Homes?
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                When you want to buy a home in San Diego, connect with a top-rated team of realtors
                who listen, are readily available, and will work tirelessly for you.{" "}
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started today!
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
